import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getIsNativePlatform } from 'utils/mobile/getIsNativePlatform';

// NOTE: CHANGE THESE
const DEV_CONFIG = {
  apiKey: 'AIzaSyDJAfrusKv3hqFpDXPaXiDQzDpe3RHxTl8',
  authDomain: 'ai-mind-staging.firebaseapp.com',
  databaseURL: 'https://ai-mind-staging-default-rtdb.firebaseio.com/',
  projectId: 'ai-mind-staging',
  storageBucket: 'ai-mind-staging.appspot.com',
  messagingSenderId: '717289184748',
  appId: '1:717289184748:web:92c861720d04654aee34c9',
};

const PROD_CONFIG = {
  apiKey: 'AIzaSyAlJIwFticgFaNZhM-s7pjn-J2Odwu_hZ4',
  authDomain: 'ai-mind-team.firebaseapp.com',
  databaseURL: 'https://ai-mind-team-default-rtdb.firebaseio.com',
  projectId: 'ai-mind-team',
  storageBucket: 'ai-mind-team.appspot.com',
  messagingSenderId: '450081184015',
  appId: '1:450081184015:web:3b0a6f55369f29c236067a',
};

export const firebaseConfig = process.env.APP_STAGE !== 'production' ? DEV_CONFIG : PROD_CONFIG;

let app: FirebaseApp | undefined;

if (!app) {
  app = initializeApp(firebaseConfig);
}

let auth: Auth;
if (getIsNativePlatform()) {
  auth = initializeAuth(app, { persistence: indexedDBLocalPersistence });
} else {
  auth = getAuth(app);
}
const storage = getStorage(app);
const database = getDatabase(app);
let analytics;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

if (process.env.APP_STAGE !== 'production') {
  console.log(app.name ? 'Firebase Mode Activated!' : 'Firebase not working :(');
}

export { auth, storage, database, analytics };
