import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, VirtualConsoleProvider } from 'context';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useApollo } from 'services/client';
import { getIsNativePlatform } from 'utils/mobile/getIsNativePlatform';
import { CurrentUserProvider } from 'context/CurrentUserContext';
import { SessionProvider } from 'context/SessionContext';
import ClientPing from 'components/ClientPing';
import 'styles/globals.css';

const APP_VIEWPORT = 'viewport-fit=cover, width=device-width, initial-scale=1';
const WEB_VIEWPORT = 'width=device-width, initial-scale=1';

export default function App({ Component, pageProps, err }: AppProps & { err: any }) {
  const apolloClient = useApollo(pageProps);
  const viewport = getIsNativePlatform() ? APP_VIEWPORT : WEB_VIEWPORT;

  return (
    <>
      <Head>
        <meta name="viewport" content={viewport} />

        {/* meta */}
        <meta name="theme-color" content="#000000" />
        <link rel="manifest" href={`${process.env.APP_URL}/manifest.json`} />
        <link rel="shortcut icon" href={`${process.env.APP_URL}/icons/favicon.png`} />
        <meta name="application-name" content="" />
        <meta name="apple-mobile-web-app-title" content="" />
        <meta name="theme-color" content="#FFFFFF" media="(prefers-color-scheme: light)" />
        <meta name="theme-color" content="#000000" media="(prefers-color-scheme: dark)" />
        {/* <link rel="mask-icon" href="/favicon-mask.svg" color="#FFF" /> */}

        {/* apple */}
        <link rel="apple-touch-icon" href={`${process.env.APP_URL}/icons/apple-icon.png`} />
      </Head>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-BWPCPQJS1S" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-BWPCPQJS1S');
        `}
      </Script>
      <SessionProvider>
        <ApolloProvider client={apolloClient}>
          <CurrentUserProvider>
            <VirtualConsoleProvider>
              <ClientPing>
                <ThemeProvider>
                  {/* <StripeProvider> */}
                  <Component {...pageProps} err={err} />
                  {/* </StripeProvider> */}
                </ThemeProvider>
              </ClientPing>
            </VirtualConsoleProvider>
          </CurrentUserProvider>
        </ApolloProvider>
      </SessionProvider>
    </>
  );
}
