import * as React from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { AuthStatus } from 'constants/auth';
import { RequestStatus } from 'constants/requests';
import { useInsertClientPingMutation } from 'types/generated/client';
import { getPlatform } from 'utils/mobile/getPlatform';
import { useUserGeo } from 'hooks/useUserGeo';
import { useViewer } from 'hooks/useViewer';

interface Props {
  children: React.ReactNode;
}

// NOTE: Will this cause uneccesarry renders and should it instead be performed in watching for route changes with events (or as a hook) and not as a component that has children?
export default function ClientPing({ children }: Props) {
  const router = useRouter();
  const viewer = useViewer();
  const userId = viewer.userId;
  const isReady = router.isReady;
  const pathname = router.pathname;
  const query = router.query;
  const [insert] = useInsertClientPingMutation();
  const geo = useUserGeo();
  const requestStatus = geo?.requestStatus;

  React.useEffect(() => {
    const clientPingRequest = async () => {
      if (
        isReady &&
        geo &&
        geo.ip &&
        viewer.status !== AuthStatus.Loading &&
        (requestStatus === RequestStatus.Success || requestStatus === RequestStatus.Error)
      ) {
        const queryString = (window.location.search || '').replace('?', '');
        insert({
          variables: {
            userId: userId || null,
            pathname,
            queryString,
            platform: getPlatform(),
            ip: geo?.ip,
            country: geo?.country,
            region: geo?.region,
            city: geo?.city,
          },
        }).catch((error) => Sentry.captureException(error));
      }
    };
    clientPingRequest();
  }, [userId, pathname, isReady, insert, query, requestStatus, viewer.status]);

  return <>{children}</>;
}
