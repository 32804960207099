import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  jsonb: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** columns and relationships of "client_pings" */
export type ClientPings = {
  __typename?: 'ClientPings';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  ip: Scalars['String']['output'];
  latitude: Scalars['String']['output'];
  longitude: Scalars['String']['output'];
  pathname: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  queryString: Scalars['String']['output'];
  region: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "client_pings" */
export type ClientPingsAggregate = {
  __typename?: 'ClientPingsAggregate';
  aggregate?: Maybe<ClientPingsAggregateFields>;
  nodes: Array<ClientPings>;
};

/** aggregate fields of "client_pings" */
export type ClientPingsAggregateFields = {
  __typename?: 'ClientPingsAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ClientPingsMaxFields>;
  min?: Maybe<ClientPingsMinFields>;
};


/** aggregate fields of "client_pings" */
export type ClientPingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClientPingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "client_pings". All fields are combined with a logical 'AND'. */
export type ClientPingsBoolExp = {
  _and?: InputMaybe<Array<ClientPingsBoolExp>>;
  _not?: InputMaybe<ClientPingsBoolExp>;
  _or?: InputMaybe<Array<ClientPingsBoolExp>>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  ip?: InputMaybe<StringComparisonExp>;
  latitude?: InputMaybe<StringComparisonExp>;
  longitude?: InputMaybe<StringComparisonExp>;
  pathname?: InputMaybe<StringComparisonExp>;
  platform?: InputMaybe<StringComparisonExp>;
  queryString?: InputMaybe<StringComparisonExp>;
  region?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "client_pings" */
export enum ClientPingsConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientPingsPkey = 'client_pings_pkey'
}

/** input type for inserting data into table "client_pings" */
export type ClientPingsInsertInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  pathname?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  queryString?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ClientPingsMaxFields = {
  __typename?: 'ClientPingsMaxFields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  pathname?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  queryString?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ClientPingsMinFields = {
  __typename?: 'ClientPingsMinFields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  pathname?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  queryString?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "client_pings" */
export type ClientPingsMutationResponse = {
  __typename?: 'ClientPingsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientPings>;
};

/** on_conflict condition type for table "client_pings" */
export type ClientPingsOnConflict = {
  constraint: ClientPingsConstraint;
  updateColumns?: Array<ClientPingsUpdateColumn>;
  where?: InputMaybe<ClientPingsBoolExp>;
};

/** Ordering options when selecting data from "client_pings". */
export type ClientPingsOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ip?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  pathname?: InputMaybe<OrderBy>;
  platform?: InputMaybe<OrderBy>;
  queryString?: InputMaybe<OrderBy>;
  region?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: client_pings */
export type ClientPingsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "client_pings" */
export enum ClientPingsSelectColumn {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Pathname = 'pathname',
  /** column name */
  Platform = 'platform',
  /** column name */
  QueryString = 'queryString',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "client_pings" */
export type ClientPingsSetInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  pathname?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  queryString?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "client_pings" */
export type ClientPingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClientPingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientPingsStreamCursorValueInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  pathname?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  queryString?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "client_pings" */
export enum ClientPingsUpdateColumn {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Pathname = 'pathname',
  /** column name */
  Platform = 'platform',
  /** column name */
  QueryString = 'queryString',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type ClientPingsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientPingsSetInput>;
  /** filter the rows which have to be updated */
  where: ClientPingsBoolExp;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "email_list" */
export type EmailList = {
  __typename?: 'EmailList';
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  ip: Scalars['String']['output'];
  listId: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  providerListId: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "email_list" */
export type EmailListAggregate = {
  __typename?: 'EmailListAggregate';
  aggregate?: Maybe<EmailListAggregateFields>;
  nodes: Array<EmailList>;
};

/** aggregate fields of "email_list" */
export type EmailListAggregateFields = {
  __typename?: 'EmailListAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<EmailListMaxFields>;
  min?: Maybe<EmailListMinFields>;
};


/** aggregate fields of "email_list" */
export type EmailListAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EmailListSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "email_list". All fields are combined with a logical 'AND'. */
export type EmailListBoolExp = {
  _and?: InputMaybe<Array<EmailListBoolExp>>;
  _not?: InputMaybe<EmailListBoolExp>;
  _or?: InputMaybe<Array<EmailListBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  ip?: InputMaybe<StringComparisonExp>;
  listId?: InputMaybe<StringComparisonExp>;
  provider?: InputMaybe<StringComparisonExp>;
  providerListId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "email_list" */
export enum EmailListConstraint {
  /** unique or primary key constraint on columns "id" */
  EmailListPkey = 'email_list_pkey'
}

/** input type for inserting data into table "email_list" */
export type EmailListInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  listId?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  providerListId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type EmailListMaxFields = {
  __typename?: 'EmailListMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  listId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  providerListId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type EmailListMinFields = {
  __typename?: 'EmailListMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  listId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  providerListId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "email_list" */
export type EmailListMutationResponse = {
  __typename?: 'EmailListMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailList>;
};

/** on_conflict condition type for table "email_list" */
export type EmailListOnConflict = {
  constraint: EmailListConstraint;
  updateColumns?: Array<EmailListUpdateColumn>;
  where?: InputMaybe<EmailListBoolExp>;
};

/** Ordering options when selecting data from "email_list". */
export type EmailListOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ip?: InputMaybe<OrderBy>;
  listId?: InputMaybe<OrderBy>;
  provider?: InputMaybe<OrderBy>;
  providerListId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: email_list */
export type EmailListPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "email_list" */
export enum EmailListSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  ListId = 'listId',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderListId = 'providerListId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "email_list" */
export type EmailListSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  listId?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  providerListId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "email_list" */
export type EmailListStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EmailListStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailListStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  listId?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  providerListId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "email_list" */
export enum EmailListUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  ListId = 'listId',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderListId = 'providerListId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type EmailListUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailListSetInput>;
  /** filter the rows which have to be updated */
  where: EmailListBoolExp;
};

/** columns and relationships of "generated_prompt_statuses" */
export type GeneratedPromptStatuses = {
  __typename?: 'GeneratedPromptStatuses';
  description?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "generated_prompt_statuses" */
export type GeneratedPromptStatusesAggregate = {
  __typename?: 'GeneratedPromptStatusesAggregate';
  aggregate?: Maybe<GeneratedPromptStatusesAggregateFields>;
  nodes: Array<GeneratedPromptStatuses>;
};

/** aggregate fields of "generated_prompt_statuses" */
export type GeneratedPromptStatusesAggregateFields = {
  __typename?: 'GeneratedPromptStatusesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<GeneratedPromptStatusesMaxFields>;
  min?: Maybe<GeneratedPromptStatusesMinFields>;
};


/** aggregate fields of "generated_prompt_statuses" */
export type GeneratedPromptStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GeneratedPromptStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "generated_prompt_statuses". All fields are combined with a logical 'AND'. */
export type GeneratedPromptStatusesBoolExp = {
  _and?: InputMaybe<Array<GeneratedPromptStatusesBoolExp>>;
  _not?: InputMaybe<GeneratedPromptStatusesBoolExp>;
  _or?: InputMaybe<Array<GeneratedPromptStatusesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "generated_prompt_statuses" */
export enum GeneratedPromptStatusesConstraint {
  /** unique or primary key constraint on columns "value" */
  GeneratedPromptStatusesPkey = 'generated_prompt_statuses_pkey'
}

export enum GeneratedPromptStatusesEnum {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

/** Boolean expression to compare columns of type "GeneratedPromptStatusesEnum". All fields are combined with logical 'AND'. */
export type GeneratedPromptStatusesEnumComparisonExp = {
  _eq?: InputMaybe<GeneratedPromptStatusesEnum>;
  _in?: InputMaybe<Array<GeneratedPromptStatusesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<GeneratedPromptStatusesEnum>;
  _nin?: InputMaybe<Array<GeneratedPromptStatusesEnum>>;
};

/** input type for inserting data into table "generated_prompt_statuses" */
export type GeneratedPromptStatusesInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type GeneratedPromptStatusesMaxFields = {
  __typename?: 'GeneratedPromptStatusesMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type GeneratedPromptStatusesMinFields = {
  __typename?: 'GeneratedPromptStatusesMinFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "generated_prompt_statuses" */
export type GeneratedPromptStatusesMutationResponse = {
  __typename?: 'GeneratedPromptStatusesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GeneratedPromptStatuses>;
};

/** on_conflict condition type for table "generated_prompt_statuses" */
export type GeneratedPromptStatusesOnConflict = {
  constraint: GeneratedPromptStatusesConstraint;
  updateColumns?: Array<GeneratedPromptStatusesUpdateColumn>;
  where?: InputMaybe<GeneratedPromptStatusesBoolExp>;
};

/** Ordering options when selecting data from "generated_prompt_statuses". */
export type GeneratedPromptStatusesOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: generated_prompt_statuses */
export type GeneratedPromptStatusesPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "generated_prompt_statuses" */
export enum GeneratedPromptStatusesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "generated_prompt_statuses" */
export type GeneratedPromptStatusesSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "generated_prompt_statuses" */
export type GeneratedPromptStatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GeneratedPromptStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GeneratedPromptStatusesStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "generated_prompt_statuses" */
export enum GeneratedPromptStatusesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type GeneratedPromptStatusesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GeneratedPromptStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: GeneratedPromptStatusesBoolExp;
};

/** columns and relationships of "generated_prompts" */
export type GeneratedPrompts = {
  __typename?: 'GeneratedPrompts';
  complexity: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  didStreamOutput: Scalars['Boolean']['output'];
  format: Scalars['String']['output'];
  generatorAiModel: Scalars['String']['output'];
  generatorAiTemperature: Scalars['String']['output'];
  generatorError: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  input: Scalars['String']['output'];
  length: Scalars['String']['output'];
  level: Scalars['String']['output'];
  persona: Scalars['String']['output'];
  presetName: Scalars['String']['output'];
  promptContext: Scalars['String']['output'];
  promptFollowup: Scalars['String']['output'];
  promptFullOutput: Scalars['String']['output'];
  promptRecommended: Scalars['String']['output'];
  status: GeneratedPromptStatusesEnum;
  tone: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userAiClient: Scalars['String']['output'];
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "generated_prompts" */
export type GeneratedPromptsAggregate = {
  __typename?: 'GeneratedPromptsAggregate';
  aggregate?: Maybe<GeneratedPromptsAggregateFields>;
  nodes: Array<GeneratedPrompts>;
};

export type GeneratedPromptsAggregateBoolExp = {
  bool_and?: InputMaybe<GeneratedPromptsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<GeneratedPromptsAggregateBoolExpBool_Or>;
  count?: InputMaybe<GeneratedPromptsAggregateBoolExpCount>;
};

/** aggregate fields of "generated_prompts" */
export type GeneratedPromptsAggregateFields = {
  __typename?: 'GeneratedPromptsAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<GeneratedPromptsMaxFields>;
  min?: Maybe<GeneratedPromptsMinFields>;
};


/** aggregate fields of "generated_prompts" */
export type GeneratedPromptsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "generated_prompts" */
export type GeneratedPromptsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GeneratedPromptsMaxOrderBy>;
  min?: InputMaybe<GeneratedPromptsMinOrderBy>;
};

/** input type for inserting array relation for remote table "generated_prompts" */
export type GeneratedPromptsArrRelInsertInput = {
  data: Array<GeneratedPromptsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GeneratedPromptsOnConflict>;
};

/** Boolean expression to filter rows from the table "generated_prompts". All fields are combined with a logical 'AND'. */
export type GeneratedPromptsBoolExp = {
  _and?: InputMaybe<Array<GeneratedPromptsBoolExp>>;
  _not?: InputMaybe<GeneratedPromptsBoolExp>;
  _or?: InputMaybe<Array<GeneratedPromptsBoolExp>>;
  complexity?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  didStreamOutput?: InputMaybe<BooleanComparisonExp>;
  format?: InputMaybe<StringComparisonExp>;
  generatorAiModel?: InputMaybe<StringComparisonExp>;
  generatorAiTemperature?: InputMaybe<StringComparisonExp>;
  generatorError?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  input?: InputMaybe<StringComparisonExp>;
  length?: InputMaybe<StringComparisonExp>;
  level?: InputMaybe<StringComparisonExp>;
  persona?: InputMaybe<StringComparisonExp>;
  presetName?: InputMaybe<StringComparisonExp>;
  promptContext?: InputMaybe<StringComparisonExp>;
  promptFollowup?: InputMaybe<StringComparisonExp>;
  promptFullOutput?: InputMaybe<StringComparisonExp>;
  promptRecommended?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<GeneratedPromptStatusesEnumComparisonExp>;
  tone?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userAiClient?: InputMaybe<StringComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "generated_prompts" */
export enum GeneratedPromptsConstraint {
  /** unique or primary key constraint on columns "id" */
  GeneratedPromptsPkey = 'generated_prompts_pkey'
}

/** input type for inserting data into table "generated_prompts" */
export type GeneratedPromptsInsertInput = {
  complexity?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  didStreamOutput?: InputMaybe<Scalars['Boolean']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  generatorAiModel?: InputMaybe<Scalars['String']['input']>;
  generatorAiTemperature?: InputMaybe<Scalars['String']['input']>;
  generatorError?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  input?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  persona?: InputMaybe<Scalars['String']['input']>;
  presetName?: InputMaybe<Scalars['String']['input']>;
  promptContext?: InputMaybe<Scalars['String']['input']>;
  promptFollowup?: InputMaybe<Scalars['String']['input']>;
  promptFullOutput?: InputMaybe<Scalars['String']['input']>;
  promptRecommended?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GeneratedPromptStatusesEnum>;
  tone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userAiClient?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type GeneratedPromptsMaxFields = {
  __typename?: 'GeneratedPromptsMaxFields';
  complexity?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  generatorAiModel?: Maybe<Scalars['String']['output']>;
  generatorAiTemperature?: Maybe<Scalars['String']['output']>;
  generatorError?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  input?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  persona?: Maybe<Scalars['String']['output']>;
  presetName?: Maybe<Scalars['String']['output']>;
  promptContext?: Maybe<Scalars['String']['output']>;
  promptFollowup?: Maybe<Scalars['String']['output']>;
  promptFullOutput?: Maybe<Scalars['String']['output']>;
  promptRecommended?: Maybe<Scalars['String']['output']>;
  tone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userAiClient?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "generated_prompts" */
export type GeneratedPromptsMaxOrderBy = {
  complexity?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  generatorAiModel?: InputMaybe<OrderBy>;
  generatorAiTemperature?: InputMaybe<OrderBy>;
  generatorError?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  input?: InputMaybe<OrderBy>;
  length?: InputMaybe<OrderBy>;
  level?: InputMaybe<OrderBy>;
  persona?: InputMaybe<OrderBy>;
  presetName?: InputMaybe<OrderBy>;
  promptContext?: InputMaybe<OrderBy>;
  promptFollowup?: InputMaybe<OrderBy>;
  promptFullOutput?: InputMaybe<OrderBy>;
  promptRecommended?: InputMaybe<OrderBy>;
  tone?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userAiClient?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GeneratedPromptsMinFields = {
  __typename?: 'GeneratedPromptsMinFields';
  complexity?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  generatorAiModel?: Maybe<Scalars['String']['output']>;
  generatorAiTemperature?: Maybe<Scalars['String']['output']>;
  generatorError?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  input?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  persona?: Maybe<Scalars['String']['output']>;
  presetName?: Maybe<Scalars['String']['output']>;
  promptContext?: Maybe<Scalars['String']['output']>;
  promptFollowup?: Maybe<Scalars['String']['output']>;
  promptFullOutput?: Maybe<Scalars['String']['output']>;
  promptRecommended?: Maybe<Scalars['String']['output']>;
  tone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userAiClient?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "generated_prompts" */
export type GeneratedPromptsMinOrderBy = {
  complexity?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  generatorAiModel?: InputMaybe<OrderBy>;
  generatorAiTemperature?: InputMaybe<OrderBy>;
  generatorError?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  input?: InputMaybe<OrderBy>;
  length?: InputMaybe<OrderBy>;
  level?: InputMaybe<OrderBy>;
  persona?: InputMaybe<OrderBy>;
  presetName?: InputMaybe<OrderBy>;
  promptContext?: InputMaybe<OrderBy>;
  promptFollowup?: InputMaybe<OrderBy>;
  promptFullOutput?: InputMaybe<OrderBy>;
  promptRecommended?: InputMaybe<OrderBy>;
  tone?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userAiClient?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "generated_prompts" */
export type GeneratedPromptsMutationResponse = {
  __typename?: 'GeneratedPromptsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GeneratedPrompts>;
};

/** on_conflict condition type for table "generated_prompts" */
export type GeneratedPromptsOnConflict = {
  constraint: GeneratedPromptsConstraint;
  updateColumns?: Array<GeneratedPromptsUpdateColumn>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};

/** Ordering options when selecting data from "generated_prompts". */
export type GeneratedPromptsOrderBy = {
  complexity?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  didStreamOutput?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  generatorAiModel?: InputMaybe<OrderBy>;
  generatorAiTemperature?: InputMaybe<OrderBy>;
  generatorError?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  input?: InputMaybe<OrderBy>;
  length?: InputMaybe<OrderBy>;
  level?: InputMaybe<OrderBy>;
  persona?: InputMaybe<OrderBy>;
  presetName?: InputMaybe<OrderBy>;
  promptContext?: InputMaybe<OrderBy>;
  promptFollowup?: InputMaybe<OrderBy>;
  promptFullOutput?: InputMaybe<OrderBy>;
  promptRecommended?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tone?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userAiClient?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: generated_prompts */
export type GeneratedPromptsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "generated_prompts" */
export enum GeneratedPromptsSelectColumn {
  /** column name */
  Complexity = 'complexity',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DidStreamOutput = 'didStreamOutput',
  /** column name */
  Format = 'format',
  /** column name */
  GeneratorAiModel = 'generatorAiModel',
  /** column name */
  GeneratorAiTemperature = 'generatorAiTemperature',
  /** column name */
  GeneratorError = 'generatorError',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  Length = 'length',
  /** column name */
  Level = 'level',
  /** column name */
  Persona = 'persona',
  /** column name */
  PresetName = 'presetName',
  /** column name */
  PromptContext = 'promptContext',
  /** column name */
  PromptFollowup = 'promptFollowup',
  /** column name */
  PromptFullOutput = 'promptFullOutput',
  /** column name */
  PromptRecommended = 'promptRecommended',
  /** column name */
  Status = 'status',
  /** column name */
  Tone = 'tone',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAiClient = 'userAiClient',
  /** column name */
  UserId = 'userId'
}

/** select "generatedPromptsAggregateBoolExpBool_andArgumentsColumns" columns of table "generated_prompts" */
export enum GeneratedPromptsSelectColumnGeneratedPromptsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  DidStreamOutput = 'didStreamOutput'
}

/** select "generatedPromptsAggregateBoolExpBool_orArgumentsColumns" columns of table "generated_prompts" */
export enum GeneratedPromptsSelectColumnGeneratedPromptsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  DidStreamOutput = 'didStreamOutput'
}

/** input type for updating data in table "generated_prompts" */
export type GeneratedPromptsSetInput = {
  complexity?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  didStreamOutput?: InputMaybe<Scalars['Boolean']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  generatorAiModel?: InputMaybe<Scalars['String']['input']>;
  generatorAiTemperature?: InputMaybe<Scalars['String']['input']>;
  generatorError?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  input?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  persona?: InputMaybe<Scalars['String']['input']>;
  presetName?: InputMaybe<Scalars['String']['input']>;
  promptContext?: InputMaybe<Scalars['String']['input']>;
  promptFollowup?: InputMaybe<Scalars['String']['input']>;
  promptFullOutput?: InputMaybe<Scalars['String']['input']>;
  promptRecommended?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GeneratedPromptStatusesEnum>;
  tone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userAiClient?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "generated_prompts" */
export type GeneratedPromptsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GeneratedPromptsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GeneratedPromptsStreamCursorValueInput = {
  complexity?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  didStreamOutput?: InputMaybe<Scalars['Boolean']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  generatorAiModel?: InputMaybe<Scalars['String']['input']>;
  generatorAiTemperature?: InputMaybe<Scalars['String']['input']>;
  generatorError?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  input?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  persona?: InputMaybe<Scalars['String']['input']>;
  presetName?: InputMaybe<Scalars['String']['input']>;
  promptContext?: InputMaybe<Scalars['String']['input']>;
  promptFollowup?: InputMaybe<Scalars['String']['input']>;
  promptFullOutput?: InputMaybe<Scalars['String']['input']>;
  promptRecommended?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GeneratedPromptStatusesEnum>;
  tone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userAiClient?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "generated_prompts" */
export enum GeneratedPromptsUpdateColumn {
  /** column name */
  Complexity = 'complexity',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DidStreamOutput = 'didStreamOutput',
  /** column name */
  Format = 'format',
  /** column name */
  GeneratorAiModel = 'generatorAiModel',
  /** column name */
  GeneratorAiTemperature = 'generatorAiTemperature',
  /** column name */
  GeneratorError = 'generatorError',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  Length = 'length',
  /** column name */
  Level = 'level',
  /** column name */
  Persona = 'persona',
  /** column name */
  PresetName = 'presetName',
  /** column name */
  PromptContext = 'promptContext',
  /** column name */
  PromptFollowup = 'promptFollowup',
  /** column name */
  PromptFullOutput = 'promptFullOutput',
  /** column name */
  PromptRecommended = 'promptRecommended',
  /** column name */
  Status = 'status',
  /** column name */
  Tone = 'tone',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAiClient = 'userAiClient',
  /** column name */
  UserId = 'userId'
}

export type GeneratedPromptsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GeneratedPromptsSetInput>;
  /** filter the rows which have to be updated */
  where: GeneratedPromptsBoolExp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "user_auth_identities" */
export type UserAuthIdentities = {
  __typename?: 'UserAuthIdentities';
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "user_auth_identities" */
export type UserAuthIdentitiesAggregate = {
  __typename?: 'UserAuthIdentitiesAggregate';
  aggregate?: Maybe<UserAuthIdentitiesAggregateFields>;
  nodes: Array<UserAuthIdentities>;
};

export type UserAuthIdentitiesAggregateBoolExp = {
  count?: InputMaybe<UserAuthIdentitiesAggregateBoolExpCount>;
};

/** aggregate fields of "user_auth_identities" */
export type UserAuthIdentitiesAggregateFields = {
  __typename?: 'UserAuthIdentitiesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserAuthIdentitiesMaxFields>;
  min?: Maybe<UserAuthIdentitiesMinFields>;
};


/** aggregate fields of "user_auth_identities" */
export type UserAuthIdentitiesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_auth_identities" */
export type UserAuthIdentitiesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserAuthIdentitiesMaxOrderBy>;
  min?: InputMaybe<UserAuthIdentitiesMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_auth_identities" */
export type UserAuthIdentitiesArrRelInsertInput = {
  data: Array<UserAuthIdentitiesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<UserAuthIdentitiesOnConflict>;
};

/** Boolean expression to filter rows from the table "user_auth_identities". All fields are combined with a logical 'AND'. */
export type UserAuthIdentitiesBoolExp = {
  _and?: InputMaybe<Array<UserAuthIdentitiesBoolExp>>;
  _not?: InputMaybe<UserAuthIdentitiesBoolExp>;
  _or?: InputMaybe<Array<UserAuthIdentitiesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  provider?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_auth_identities" */
export enum UserAuthIdentitiesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserAuthIdentitiesPkey = 'user_auth_identities_pkey',
  /** unique or primary key constraint on columns "email", "provider", "user_id" */
  UserAuthIdentitiesUserIdEmailProviderKey = 'user_auth_identities_user_id_email_provider_key'
}

/** input type for inserting data into table "user_auth_identities" */
export type UserAuthIdentitiesInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type UserAuthIdentitiesMaxFields = {
  __typename?: 'UserAuthIdentitiesMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_auth_identities" */
export type UserAuthIdentitiesMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  provider?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserAuthIdentitiesMinFields = {
  __typename?: 'UserAuthIdentitiesMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_auth_identities" */
export type UserAuthIdentitiesMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  provider?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_auth_identities" */
export type UserAuthIdentitiesMutationResponse = {
  __typename?: 'UserAuthIdentitiesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAuthIdentities>;
};

/** on_conflict condition type for table "user_auth_identities" */
export type UserAuthIdentitiesOnConflict = {
  constraint: UserAuthIdentitiesConstraint;
  updateColumns?: Array<UserAuthIdentitiesUpdateColumn>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};

/** Ordering options when selecting data from "user_auth_identities". */
export type UserAuthIdentitiesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  provider?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_auth_identities */
export type UserAuthIdentitiesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_auth_identities" */
export enum UserAuthIdentitiesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_auth_identities" */
export type UserAuthIdentitiesSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_auth_identities" */
export type UserAuthIdentitiesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserAuthIdentitiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserAuthIdentitiesStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_auth_identities" */
export enum UserAuthIdentitiesUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type UserAuthIdentitiesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAuthIdentitiesSetInput>;
  /** filter the rows which have to be updated */
  where: UserAuthIdentitiesBoolExp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'Users';
  aboutMe: Scalars['String']['output'];
  /** An array relationship */
  authIdentities: Array<UserAuthIdentities>;
  /** An aggregate relationship */
  authIdentitiesAggregate: UserAuthIdentitiesAggregate;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  firebaseId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  /** An array relationship */
  generatedPrompts: Array<GeneratedPrompts>;
  /** An aggregate relationship */
  generatedPromptsAggregate: GeneratedPromptsAggregate;
  id: Scalars['uuid']['output'];
  lastAuthProvider: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  originalAuthProvider: Scalars['String']['output'];
  profileImageUrl: Scalars['String']['output'];
  signupProviderData?: Maybe<Scalars['jsonb']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "users" */
export type UsersAuthIdentitiesArgs = {
  distinctOn?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAuthIdentitiesOrderBy>>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersAuthIdentitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAuthIdentitiesOrderBy>>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersGeneratedPromptsArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptsOrderBy>>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersGeneratedPromptsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptsOrderBy>>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersSignupProviderDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'UsersAggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'UsersAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};


/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UsersAppendInput = {
  signupProviderData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  aboutMe?: InputMaybe<StringComparisonExp>;
  authIdentities?: InputMaybe<UserAuthIdentitiesBoolExp>;
  authIdentitiesAggregate?: InputMaybe<UserAuthIdentitiesAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  firebaseId?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  generatedPrompts?: InputMaybe<GeneratedPromptsBoolExp>;
  generatedPromptsAggregate?: InputMaybe<GeneratedPromptsAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lastAuthProvider?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  originalAuthProvider?: InputMaybe<StringComparisonExp>;
  profileImageUrl?: InputMaybe<StringComparisonExp>;
  signupProviderData?: InputMaybe<JsonbComparisonExp>;
  stripeCustomerId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "firebase_id" */
  UsersFirebaseIdKey = 'users_firebase_id_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "stripe_customer_id" */
  UsersStripeCustomerIdKey = 'users_stripe_customer_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UsersDeleteAtPathInput = {
  signupProviderData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UsersDeleteElemInput = {
  signupProviderData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UsersDeleteKeyInput = {
  signupProviderData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  authIdentities?: InputMaybe<UserAuthIdentitiesArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firebaseId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  generatedPrompts?: InputMaybe<GeneratedPromptsArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastAuthProvider?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  originalAuthProvider?: InputMaybe<Scalars['String']['input']>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  signupProviderData?: InputMaybe<Scalars['jsonb']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'UsersMaxFields';
  aboutMe?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firebaseId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastAuthProvider?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  originalAuthProvider?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'UsersMinFields';
  aboutMe?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firebaseId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastAuthProvider?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  originalAuthProvider?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'UsersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  updateColumns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  aboutMe?: InputMaybe<OrderBy>;
  authIdentitiesAggregate?: InputMaybe<UserAuthIdentitiesAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firebaseId?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  generatedPromptsAggregate?: InputMaybe<GeneratedPromptsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  lastAuthProvider?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  originalAuthProvider?: InputMaybe<OrderBy>;
  profileImageUrl?: InputMaybe<OrderBy>;
  signupProviderData?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UsersPrependInput = {
  signupProviderData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  AboutMe = 'aboutMe',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebaseId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastAuthProvider = 'lastAuthProvider',
  /** column name */
  LastName = 'lastName',
  /** column name */
  OriginalAuthProvider = 'originalAuthProvider',
  /** column name */
  ProfileImageUrl = 'profileImageUrl',
  /** column name */
  SignupProviderData = 'signupProviderData',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firebaseId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastAuthProvider?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  originalAuthProvider?: InputMaybe<Scalars['String']['input']>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  signupProviderData?: InputMaybe<Scalars['jsonb']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firebaseId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastAuthProvider?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  originalAuthProvider?: InputMaybe<Scalars['String']['input']>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  signupProviderData?: InputMaybe<Scalars['jsonb']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  AboutMe = 'aboutMe',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebaseId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastAuthProvider = 'lastAuthProvider',
  /** column name */
  LastName = 'lastName',
  /** column name */
  OriginalAuthProvider = 'originalAuthProvider',
  /** column name */
  ProfileImageUrl = 'profileImageUrl',
  /** column name */
  SignupProviderData = 'signupProviderData',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type UsersUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UsersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<UsersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<UsersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<UsersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UsersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type GeneratedPromptsAggregateBoolExpBool_And = {
  arguments: GeneratedPromptsSelectColumnGeneratedPromptsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GeneratedPromptsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type GeneratedPromptsAggregateBoolExpBool_Or = {
  arguments: GeneratedPromptsSelectColumnGeneratedPromptsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GeneratedPromptsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type GeneratedPromptsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GeneratedPromptsBoolExp>;
  predicate: IntComparisonExp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "client_pings" */
  deleteClientPings?: Maybe<ClientPingsMutationResponse>;
  /** delete single row from the table: "client_pings" */
  deleteClientPingsByPk?: Maybe<ClientPings>;
  /** delete data from the table: "email_list" */
  deleteEmailList?: Maybe<EmailListMutationResponse>;
  /** delete single row from the table: "email_list" */
  deleteEmailListByPk?: Maybe<EmailList>;
  /** delete data from the table: "generated_prompt_statuses" */
  deleteGeneratedPromptStatuses?: Maybe<GeneratedPromptStatusesMutationResponse>;
  /** delete single row from the table: "generated_prompt_statuses" */
  deleteGeneratedPromptStatusesByPk?: Maybe<GeneratedPromptStatuses>;
  /** delete data from the table: "generated_prompts" */
  deleteGeneratedPrompts?: Maybe<GeneratedPromptsMutationResponse>;
  /** delete single row from the table: "generated_prompts" */
  deleteGeneratedPromptsByPk?: Maybe<GeneratedPrompts>;
  /** delete data from the table: "user_auth_identities" */
  deleteUserAuthIdentities?: Maybe<UserAuthIdentitiesMutationResponse>;
  /** delete single row from the table: "user_auth_identities" */
  deleteUserAuthIdentitiesByPk?: Maybe<UserAuthIdentities>;
  /** delete data from the table: "users" */
  deleteUsers?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  deleteUsersByPk?: Maybe<Users>;
  /** insert data into the table: "client_pings" */
  insertClientPings?: Maybe<ClientPingsMutationResponse>;
  /** insert a single row into the table: "client_pings" */
  insertClientPingsOne?: Maybe<ClientPings>;
  /** insert data into the table: "email_list" */
  insertEmailList?: Maybe<EmailListMutationResponse>;
  /** insert a single row into the table: "email_list" */
  insertEmailListOne?: Maybe<EmailList>;
  /** insert data into the table: "generated_prompt_statuses" */
  insertGeneratedPromptStatuses?: Maybe<GeneratedPromptStatusesMutationResponse>;
  /** insert a single row into the table: "generated_prompt_statuses" */
  insertGeneratedPromptStatusesOne?: Maybe<GeneratedPromptStatuses>;
  /** insert data into the table: "generated_prompts" */
  insertGeneratedPrompts?: Maybe<GeneratedPromptsMutationResponse>;
  /** insert a single row into the table: "generated_prompts" */
  insertGeneratedPromptsOne?: Maybe<GeneratedPrompts>;
  /** insert data into the table: "user_auth_identities" */
  insertUserAuthIdentities?: Maybe<UserAuthIdentitiesMutationResponse>;
  /** insert a single row into the table: "user_auth_identities" */
  insertUserAuthIdentitiesOne?: Maybe<UserAuthIdentities>;
  /** insert data into the table: "users" */
  insertUsers?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insertUsersOne?: Maybe<Users>;
  /** update data of the table: "client_pings" */
  updateClientPings?: Maybe<ClientPingsMutationResponse>;
  /** update single row of the table: "client_pings" */
  updateClientPingsByPk?: Maybe<ClientPings>;
  /** update multiples rows of table: "client_pings" */
  updateClientPingsMany?: Maybe<Array<Maybe<ClientPingsMutationResponse>>>;
  /** update data of the table: "email_list" */
  updateEmailList?: Maybe<EmailListMutationResponse>;
  /** update single row of the table: "email_list" */
  updateEmailListByPk?: Maybe<EmailList>;
  /** update multiples rows of table: "email_list" */
  updateEmailListMany?: Maybe<Array<Maybe<EmailListMutationResponse>>>;
  /** update data of the table: "generated_prompt_statuses" */
  updateGeneratedPromptStatuses?: Maybe<GeneratedPromptStatusesMutationResponse>;
  /** update single row of the table: "generated_prompt_statuses" */
  updateGeneratedPromptStatusesByPk?: Maybe<GeneratedPromptStatuses>;
  /** update multiples rows of table: "generated_prompt_statuses" */
  updateGeneratedPromptStatusesMany?: Maybe<Array<Maybe<GeneratedPromptStatusesMutationResponse>>>;
  /** update data of the table: "generated_prompts" */
  updateGeneratedPrompts?: Maybe<GeneratedPromptsMutationResponse>;
  /** update single row of the table: "generated_prompts" */
  updateGeneratedPromptsByPk?: Maybe<GeneratedPrompts>;
  /** update multiples rows of table: "generated_prompts" */
  updateGeneratedPromptsMany?: Maybe<Array<Maybe<GeneratedPromptsMutationResponse>>>;
  /** update data of the table: "user_auth_identities" */
  updateUserAuthIdentities?: Maybe<UserAuthIdentitiesMutationResponse>;
  /** update single row of the table: "user_auth_identities" */
  updateUserAuthIdentitiesByPk?: Maybe<UserAuthIdentities>;
  /** update multiples rows of table: "user_auth_identities" */
  updateUserAuthIdentitiesMany?: Maybe<Array<Maybe<UserAuthIdentitiesMutationResponse>>>;
  /** update data of the table: "users" */
  updateUsers?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  updateUsersByPk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  updateUsersMany?: Maybe<Array<Maybe<UsersMutationResponse>>>;
};


/** mutation root */
export type Mutation_RootDeleteClientPingsArgs = {
  where: ClientPingsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClientPingsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteEmailListArgs = {
  where: EmailListBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteEmailListByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteGeneratedPromptStatusesArgs = {
  where: GeneratedPromptStatusesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteGeneratedPromptStatusesByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteGeneratedPromptsArgs = {
  where: GeneratedPromptsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteGeneratedPromptsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteUserAuthIdentitiesArgs = {
  where: UserAuthIdentitiesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUserAuthIdentitiesByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: UsersBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUsersByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsertClientPingsArgs = {
  objects: Array<ClientPingsInsertInput>;
  onConflict?: InputMaybe<ClientPingsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClientPingsOneArgs = {
  object: ClientPingsInsertInput;
  onConflict?: InputMaybe<ClientPingsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertEmailListArgs = {
  objects: Array<EmailListInsertInput>;
  onConflict?: InputMaybe<EmailListOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertEmailListOneArgs = {
  object: EmailListInsertInput;
  onConflict?: InputMaybe<EmailListOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGeneratedPromptStatusesArgs = {
  objects: Array<GeneratedPromptStatusesInsertInput>;
  onConflict?: InputMaybe<GeneratedPromptStatusesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGeneratedPromptStatusesOneArgs = {
  object: GeneratedPromptStatusesInsertInput;
  onConflict?: InputMaybe<GeneratedPromptStatusesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGeneratedPromptsArgs = {
  objects: Array<GeneratedPromptsInsertInput>;
  onConflict?: InputMaybe<GeneratedPromptsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGeneratedPromptsOneArgs = {
  object: GeneratedPromptsInsertInput;
  onConflict?: InputMaybe<GeneratedPromptsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserAuthIdentitiesArgs = {
  objects: Array<UserAuthIdentitiesInsertInput>;
  onConflict?: InputMaybe<UserAuthIdentitiesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserAuthIdentitiesOneArgs = {
  object: UserAuthIdentitiesInsertInput;
  onConflict?: InputMaybe<UserAuthIdentitiesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  onConflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUsersOneArgs = {
  object: UsersInsertInput;
  onConflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateClientPingsArgs = {
  _set?: InputMaybe<ClientPingsSetInput>;
  where: ClientPingsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClientPingsByPkArgs = {
  _set?: InputMaybe<ClientPingsSetInput>;
  pkColumns: ClientPingsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClientPingsManyArgs = {
  updates: Array<ClientPingsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateEmailListArgs = {
  _set?: InputMaybe<EmailListSetInput>;
  where: EmailListBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateEmailListByPkArgs = {
  _set?: InputMaybe<EmailListSetInput>;
  pkColumns: EmailListPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateEmailListManyArgs = {
  updates: Array<EmailListUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedPromptStatusesArgs = {
  _set?: InputMaybe<GeneratedPromptStatusesSetInput>;
  where: GeneratedPromptStatusesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedPromptStatusesByPkArgs = {
  _set?: InputMaybe<GeneratedPromptStatusesSetInput>;
  pkColumns: GeneratedPromptStatusesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedPromptStatusesManyArgs = {
  updates: Array<GeneratedPromptStatusesUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedPromptsArgs = {
  _set?: InputMaybe<GeneratedPromptsSetInput>;
  where: GeneratedPromptsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedPromptsByPkArgs = {
  _set?: InputMaybe<GeneratedPromptsSetInput>;
  pkColumns: GeneratedPromptsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedPromptsManyArgs = {
  updates: Array<GeneratedPromptsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateUserAuthIdentitiesArgs = {
  _set?: InputMaybe<UserAuthIdentitiesSetInput>;
  where: UserAuthIdentitiesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUserAuthIdentitiesByPkArgs = {
  _set?: InputMaybe<UserAuthIdentitiesSetInput>;
  pkColumns: UserAuthIdentitiesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateUserAuthIdentitiesManyArgs = {
  updates: Array<UserAuthIdentitiesUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _append?: InputMaybe<UsersAppendInput>;
  _deleteAtPath?: InputMaybe<UsersDeleteAtPathInput>;
  _deleteElem?: InputMaybe<UsersDeleteElemInput>;
  _deleteKey?: InputMaybe<UsersDeleteKeyInput>;
  _prepend?: InputMaybe<UsersPrependInput>;
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUsersByPkArgs = {
  _append?: InputMaybe<UsersAppendInput>;
  _deleteAtPath?: InputMaybe<UsersDeleteAtPathInput>;
  _deleteElem?: InputMaybe<UsersDeleteElemInput>;
  _deleteKey?: InputMaybe<UsersDeleteKeyInput>;
  _prepend?: InputMaybe<UsersPrependInput>;
  _set?: InputMaybe<UsersSetInput>;
  pkColumns: UsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "client_pings" */
  clientPings: Array<ClientPings>;
  /** fetch aggregated fields from the table: "client_pings" */
  clientPingsAggregate: ClientPingsAggregate;
  /** fetch data from the table: "client_pings" using primary key columns */
  clientPingsByPk?: Maybe<ClientPings>;
  /** fetch data from the table: "email_list" */
  emailList: Array<EmailList>;
  /** fetch aggregated fields from the table: "email_list" */
  emailListAggregate: EmailListAggregate;
  /** fetch data from the table: "email_list" using primary key columns */
  emailListByPk?: Maybe<EmailList>;
  /** fetch data from the table: "generated_prompt_statuses" */
  generatedPromptStatuses: Array<GeneratedPromptStatuses>;
  /** fetch aggregated fields from the table: "generated_prompt_statuses" */
  generatedPromptStatusesAggregate: GeneratedPromptStatusesAggregate;
  /** fetch data from the table: "generated_prompt_statuses" using primary key columns */
  generatedPromptStatusesByPk?: Maybe<GeneratedPromptStatuses>;
  /** An array relationship */
  generatedPrompts: Array<GeneratedPrompts>;
  /** An aggregate relationship */
  generatedPromptsAggregate: GeneratedPromptsAggregate;
  /** fetch data from the table: "generated_prompts" using primary key columns */
  generatedPromptsByPk?: Maybe<GeneratedPrompts>;
  /** fetch data from the table: "user_auth_identities" */
  userAuthIdentities: Array<UserAuthIdentities>;
  /** fetch aggregated fields from the table: "user_auth_identities" */
  userAuthIdentitiesAggregate: UserAuthIdentitiesAggregate;
  /** fetch data from the table: "user_auth_identities" using primary key columns */
  userAuthIdentitiesByPk?: Maybe<UserAuthIdentities>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  usersByPk?: Maybe<Users>;
};


export type Query_RootClientPingsArgs = {
  distinctOn?: InputMaybe<Array<ClientPingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientPingsOrderBy>>;
  where?: InputMaybe<ClientPingsBoolExp>;
};


export type Query_RootClientPingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientPingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientPingsOrderBy>>;
  where?: InputMaybe<ClientPingsBoolExp>;
};


export type Query_RootClientPingsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootEmailListArgs = {
  distinctOn?: InputMaybe<Array<EmailListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailListOrderBy>>;
  where?: InputMaybe<EmailListBoolExp>;
};


export type Query_RootEmailListAggregateArgs = {
  distinctOn?: InputMaybe<Array<EmailListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailListOrderBy>>;
  where?: InputMaybe<EmailListBoolExp>;
};


export type Query_RootEmailListByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootGeneratedPromptStatusesArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptStatusesOrderBy>>;
  where?: InputMaybe<GeneratedPromptStatusesBoolExp>;
};


export type Query_RootGeneratedPromptStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptStatusesOrderBy>>;
  where?: InputMaybe<GeneratedPromptStatusesBoolExp>;
};


export type Query_RootGeneratedPromptStatusesByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootGeneratedPromptsArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptsOrderBy>>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};


export type Query_RootGeneratedPromptsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptsOrderBy>>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};


export type Query_RootGeneratedPromptsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUserAuthIdentitiesArgs = {
  distinctOn?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAuthIdentitiesOrderBy>>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};


export type Query_RootUserAuthIdentitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAuthIdentitiesOrderBy>>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};


export type Query_RootUserAuthIdentitiesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Query_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Query_RootUsersByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "client_pings" */
  clientPings: Array<ClientPings>;
  /** fetch aggregated fields from the table: "client_pings" */
  clientPingsAggregate: ClientPingsAggregate;
  /** fetch data from the table: "client_pings" using primary key columns */
  clientPingsByPk?: Maybe<ClientPings>;
  /** fetch data from the table in a streaming manner: "client_pings" */
  clientPingsStream: Array<ClientPings>;
  /** fetch data from the table: "email_list" */
  emailList: Array<EmailList>;
  /** fetch aggregated fields from the table: "email_list" */
  emailListAggregate: EmailListAggregate;
  /** fetch data from the table: "email_list" using primary key columns */
  emailListByPk?: Maybe<EmailList>;
  /** fetch data from the table in a streaming manner: "email_list" */
  emailListStream: Array<EmailList>;
  /** fetch data from the table: "generated_prompt_statuses" */
  generatedPromptStatuses: Array<GeneratedPromptStatuses>;
  /** fetch aggregated fields from the table: "generated_prompt_statuses" */
  generatedPromptStatusesAggregate: GeneratedPromptStatusesAggregate;
  /** fetch data from the table: "generated_prompt_statuses" using primary key columns */
  generatedPromptStatusesByPk?: Maybe<GeneratedPromptStatuses>;
  /** fetch data from the table in a streaming manner: "generated_prompt_statuses" */
  generatedPromptStatusesStream: Array<GeneratedPromptStatuses>;
  /** An array relationship */
  generatedPrompts: Array<GeneratedPrompts>;
  /** An aggregate relationship */
  generatedPromptsAggregate: GeneratedPromptsAggregate;
  /** fetch data from the table: "generated_prompts" using primary key columns */
  generatedPromptsByPk?: Maybe<GeneratedPrompts>;
  /** fetch data from the table in a streaming manner: "generated_prompts" */
  generatedPromptsStream: Array<GeneratedPrompts>;
  /** fetch data from the table: "user_auth_identities" */
  userAuthIdentities: Array<UserAuthIdentities>;
  /** fetch aggregated fields from the table: "user_auth_identities" */
  userAuthIdentitiesAggregate: UserAuthIdentitiesAggregate;
  /** fetch data from the table: "user_auth_identities" using primary key columns */
  userAuthIdentitiesByPk?: Maybe<UserAuthIdentities>;
  /** fetch data from the table in a streaming manner: "user_auth_identities" */
  userAuthIdentitiesStream: Array<UserAuthIdentities>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  usersStream: Array<Users>;
};


export type Subscription_RootClientPingsArgs = {
  distinctOn?: InputMaybe<Array<ClientPingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientPingsOrderBy>>;
  where?: InputMaybe<ClientPingsBoolExp>;
};


export type Subscription_RootClientPingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientPingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientPingsOrderBy>>;
  where?: InputMaybe<ClientPingsBoolExp>;
};


export type Subscription_RootClientPingsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootClientPingsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ClientPingsStreamCursorInput>>;
  where?: InputMaybe<ClientPingsBoolExp>;
};


export type Subscription_RootEmailListArgs = {
  distinctOn?: InputMaybe<Array<EmailListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailListOrderBy>>;
  where?: InputMaybe<EmailListBoolExp>;
};


export type Subscription_RootEmailListAggregateArgs = {
  distinctOn?: InputMaybe<Array<EmailListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailListOrderBy>>;
  where?: InputMaybe<EmailListBoolExp>;
};


export type Subscription_RootEmailListByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootEmailListStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EmailListStreamCursorInput>>;
  where?: InputMaybe<EmailListBoolExp>;
};


export type Subscription_RootGeneratedPromptStatusesArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptStatusesOrderBy>>;
  where?: InputMaybe<GeneratedPromptStatusesBoolExp>;
};


export type Subscription_RootGeneratedPromptStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptStatusesOrderBy>>;
  where?: InputMaybe<GeneratedPromptStatusesBoolExp>;
};


export type Subscription_RootGeneratedPromptStatusesByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootGeneratedPromptStatusesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GeneratedPromptStatusesStreamCursorInput>>;
  where?: InputMaybe<GeneratedPromptStatusesBoolExp>;
};


export type Subscription_RootGeneratedPromptsArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptsOrderBy>>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};


export type Subscription_RootGeneratedPromptsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedPromptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneratedPromptsOrderBy>>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};


export type Subscription_RootGeneratedPromptsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootGeneratedPromptsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GeneratedPromptsStreamCursorInput>>;
  where?: InputMaybe<GeneratedPromptsBoolExp>;
};


export type Subscription_RootUserAuthIdentitiesArgs = {
  distinctOn?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAuthIdentitiesOrderBy>>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};


export type Subscription_RootUserAuthIdentitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAuthIdentitiesOrderBy>>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};


export type Subscription_RootUserAuthIdentitiesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUserAuthIdentitiesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserAuthIdentitiesStreamCursorInput>>;
  where?: InputMaybe<UserAuthIdentitiesBoolExp>;
};


export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Subscription_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Subscription_RootUsersByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type UserAuthIdentitiesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserAuthIdentitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAuthIdentitiesBoolExp>;
  predicate: IntComparisonExp;
};

export type InsertClientPingMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']['input']>;
  pathname?: InputMaybe<Scalars['String']['input']>;
  queryString?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
}>;


export type InsertClientPingMutation = { __typename?: 'mutation_root', insertClientPingsOne?: { __typename?: 'ClientPings', id: any } | null };

export type GetExistingUserQueryVariables = Exact<{
  firebaseId: Scalars['String']['input'];
}>;


export type GetExistingUserQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'Users', id: any, email: string, fullName: string }> };

export type GetCurrentUserQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetCurrentUserQuery = { __typename?: 'query_root', usersByPk?: { __typename?: 'Users', id: any, email: string, firstName: string, lastName: string } | null };

export type GetGeneratedPromptHistoryQueryVariables = Exact<{
  userId: Scalars['uuid']['input'];
}>;


export type GetGeneratedPromptHistoryQuery = { __typename?: 'query_root', generatedPrompts: Array<{ __typename?: 'GeneratedPrompts', complexity: string, createdAt: any, format: string, generatorAiModel: string, generatorAiTemperature: string, generatorError: string, id: any, input: string, length: string, level: string, persona: string, presetName: string, promptContext: string, promptFollowup: string, promptFullOutput: string, promptRecommended: string, status: GeneratedPromptStatusesEnum, tone: string, userAiClient: string }> };


export const InsertClientPingDocument = gql`
    mutation insertClientPing($userId: uuid, $pathname: String = "", $queryString: String = "", $platform: String = "", $ip: String = "", $country: String = "", $region: String = "", $city: String = "") {
  insertClientPingsOne(
    object: {userId: $userId, pathname: $pathname, queryString: $queryString, platform: $platform, ip: $ip, country: $country, region: $region, city: $city}
  ) {
    id
  }
}
    `;
export type InsertClientPingMutationFn = Apollo.MutationFunction<InsertClientPingMutation, InsertClientPingMutationVariables>;

/**
 * __useInsertClientPingMutation__
 *
 * To run a mutation, you first call `useInsertClientPingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientPingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientPingMutation, { data, loading, error }] = useInsertClientPingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      pathname: // value for 'pathname'
 *      queryString: // value for 'queryString'
 *      platform: // value for 'platform'
 *      ip: // value for 'ip'
 *      country: // value for 'country'
 *      region: // value for 'region'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useInsertClientPingMutation(baseOptions?: Apollo.MutationHookOptions<InsertClientPingMutation, InsertClientPingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertClientPingMutation, InsertClientPingMutationVariables>(InsertClientPingDocument, options);
      }
export type InsertClientPingMutationHookResult = ReturnType<typeof useInsertClientPingMutation>;
export type InsertClientPingMutationResult = Apollo.MutationResult<InsertClientPingMutation>;
export type InsertClientPingMutationOptions = Apollo.BaseMutationOptions<InsertClientPingMutation, InsertClientPingMutationVariables>;
export const GetExistingUserDocument = gql`
    query getExistingUser($firebaseId: String!) {
  users(where: {firebaseId: {_eq: $firebaseId}}) {
    id
    email
    fullName
  }
}
    `;

/**
 * __useGetExistingUserQuery__
 *
 * To run a query within a React component, call `useGetExistingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingUserQuery({
 *   variables: {
 *      firebaseId: // value for 'firebaseId'
 *   },
 * });
 */
export function useGetExistingUserQuery(baseOptions: Apollo.QueryHookOptions<GetExistingUserQuery, GetExistingUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingUserQuery, GetExistingUserQueryVariables>(GetExistingUserDocument, options);
      }
export function useGetExistingUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingUserQuery, GetExistingUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingUserQuery, GetExistingUserQueryVariables>(GetExistingUserDocument, options);
        }
export type GetExistingUserQueryHookResult = ReturnType<typeof useGetExistingUserQuery>;
export type GetExistingUserLazyQueryHookResult = ReturnType<typeof useGetExistingUserLazyQuery>;
export type GetExistingUserQueryResult = Apollo.QueryResult<GetExistingUserQuery, GetExistingUserQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser($id: uuid!) {
  usersByPk(id: $id) {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetGeneratedPromptHistoryDocument = gql`
    query getGeneratedPromptHistory($userId: uuid!) {
  generatedPrompts(orderBy: {createdAt: DESC}, where: {userId: {_eq: $userId}}) {
    complexity
    createdAt
    format
    generatorAiModel
    generatorAiTemperature
    generatorError
    id
    input
    length
    level
    persona
    presetName
    promptContext
    promptFollowup
    promptFullOutput
    promptRecommended
    status
    tone
    userAiClient
  }
}
    `;

/**
 * __useGetGeneratedPromptHistoryQuery__
 *
 * To run a query within a React component, call `useGetGeneratedPromptHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedPromptHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedPromptHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetGeneratedPromptHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetGeneratedPromptHistoryQuery, GetGeneratedPromptHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneratedPromptHistoryQuery, GetGeneratedPromptHistoryQueryVariables>(GetGeneratedPromptHistoryDocument, options);
      }
export function useGetGeneratedPromptHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedPromptHistoryQuery, GetGeneratedPromptHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneratedPromptHistoryQuery, GetGeneratedPromptHistoryQueryVariables>(GetGeneratedPromptHistoryDocument, options);
        }
export type GetGeneratedPromptHistoryQueryHookResult = ReturnType<typeof useGetGeneratedPromptHistoryQuery>;
export type GetGeneratedPromptHistoryLazyQueryHookResult = ReturnType<typeof useGetGeneratedPromptHistoryLazyQuery>;
export type GetGeneratedPromptHistoryQueryResult = Apollo.QueryResult<GetGeneratedPromptHistoryQuery, GetGeneratedPromptHistoryQueryVariables>;